<template>
  <main id="oem">
    <header-slider
      class="hidden desktopSmall:block"
      :slides="page.acf.header_slider" />
      <div class="container">
        <div class="w-full tablet:w-3/4 mx-auto my-5">
          <header-h1
            :content="`OEM: ${oemNumber}`"
            casing="uppercase"
            mt="0"
            mb="0"
            extraClass="text-h2" />
          <header-h2
            :content="`${$t('components.oem.oem_txt', {oem_number: oemNumber})}`"
            mt="4"
            mb="10"
            extraClass="text-base" />

          <div id="oem_products" class="pb-5">
            <section>
              <div class="flex flex-wrap">
                <div class="w-full">
                  <div v-if="!products.length">
                    {{ $t('components.oem.no_oem_found', {oem_number: oemNumber}) }}
                  </div>
                  <div v-for="product in products">
                    <product-item
                      :data="product"
                      :link="{
                        name: 'product-product',
                        params: {
                          product: $slugify(product.link)
                        }
                      }"/>
                  </div>

                  <p class="text-sm">
                    {{ $t('components.oem.oem_replacement_part_info') }}
                  </p>
                </div>
              </div>
            </section>
          </div>
        </div>
      </div>
  </main>
</template>

<script>
import portal from '@/service/portal';
import wordpress from '@/service/wordpress';
import HeaderSlider from '@/components/headers/Slider';
import ProductItem from '@/components/products/Item';

export default {
  name: 'OemItem',
  components: {
    ProductItem,
    HeaderSlider
  },
  head () {
    const title =
      this.page.yoast.yoast_wpseo_title === ''
        ? this.page.title + ' - Air Conditioning Parts - AP Air Ltd.'
        : this.page.yoast.yoast_wpseo_title;
    const description =
      this.page.yoast.yoast_wpseo_metadesc === ''
        ? this.page.excerpt.replace(/<(?:.|\n)*?>/gm, '')
        : this.page.yoast.yoast_wpseo_metadesc;

    return {
      title,
      titleTemplate: title,
      meta: [
        {
          hid: 'description',
          name: 'description',
          content: description
        },
        { property: 'og:locale', content: 'en_GB' },
        { property: 'og:type', content: 'page' },
        { property: 'og:title', content: title },
        { property: 'og:description', content: description },
        {
          property: 'og:url',
          content: 'https://www.apairltd.com' + this.$route.fullPath
        },
        { property: 'og:site_name', content: 'AP Air Ltd.' },
        {
          property: 'og:article:published_time',
          content: this.page.date
        },
        {
          property: 'og:article:modified_time',
          content: this.page.date_modified
        },
        {
          property: 'og:og:updated_time',
          content: this.page.date_modified
        }
      ]
    }
  },
  data () {
    return {
    }
  },
  async asyncData ({ params, app, store }) {
    const [page, products] = await Promise.all([
      wordpress.get(`page/oem`),
      portal.get(`oem/${params.oemNumber}?lang=${store.state.language}`)
    ])

    products.data.map((product) => {
      for (let j = 0; j < product.translations.length; j++) {
        if (product.translations[j].language.language_code === store.state.language ) {
          product.name = product.translations[j].name;
        }
      }
      product.link = product.id + ' ' + product.sku + ' ' + product.name;
    })

    return {
      oemNumber: params.oemNumber,
      page: page.data,
      products: products.data
    }
  }
}
</script>

<style>
div {
  word-wrap: break-word;
}
</style>
